<template>
  <v-app style="background-color: #eceff1">
    <notifications group="gral_notif" position="bottom right" :width="400" />
    <v-app-bar color="primary" app dark>
      <!-- <MainMenu/> -->
      <v-toolbar-title class="headline text-uppercase">
        <span>Sesiones CGU</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small icon outlined @click="cerrarSesion()" style="margin-right: 10px"
        ><v-icon>mdi-power</v-icon></v-btn
      >
    </v-app-bar>

    <router-view></router-view>

    <main-footer />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { getSession } from "./main.service";

// import MainMenu from "./mainMenu";
import MainFooter from "./mainFooter";

export default {
  name: "mainView",
  components: {
    // MainMenu,
    MainFooter,
  },
  computed: {
    ...mapGetters(["masterApp", "userName"]),
  },
  async created() {
    try {
      const token = localStorage.getItem("cgu-token");
      if (!token) throw new Error("Sesión no válida");
      this.$store.dispatch("setSessionToken", token);
      this.loading = true;
      const serverResponse = await getSession();
      if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
      this.$store.dispatch("setCodigoUsuario", serverResponse.codigo);
      this.$store.dispatch("setUserName", serverResponse.nombre);
      this.$store.dispatch("setUserAdmin", serverResponse.admin);
      this.$store.dispatch("setUserId", serverResponse.usuarioMaster);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.$notificarError(error);
      window.open(`${this.masterApp}/apps`, "_self");
    }
  },
  data: () => ({
    loading: false,
    menu: [],
  }),
  methods: {
    cerrarSesion() {
      localStorage.removeItem("cgu-token");
      this.$store.dispatch("setSessionToken", null);
      window.open(`${this.masterApp}/apps`, "_self");
    },
  },
};
</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}
.link {
  text-decoration: none;
}
</style>